import axios from 'axios'

import {
    Message
} from 'element-ui'


import {
    getToken
} from '@/utils/auth'


const service = axios.create({
    baseURL: process.env.NODE_ENV=="development"?"https://dy.yskcrm.com/":"/",
    timeout: 10000
})

// request interceptor
service.interceptors.request.use(
    config => {

        if (getToken()) {
            config.headers['authorization'] = getToken()
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(

    response => {
        const res = response.data
        //如果不等于0表示请求非正常
        if (res.code !== 0) {
            Message({
                message: res.message,
                type: 'error',
                duration: 5 * 1000
            })

            //4001表示没有登录权限。
            if(res.code==4001){
                location.href = '/login';
                return ;
            }
            return res;
        }else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
)

export default service
